.UpdateButtons_PersonalInformation {
  margin-bottom: 15px;
}

.uploadDocument {
  display: flex;
}

.buttons__rhAdmin {
  display: flex;
}

#rhCollaborator_controlGroup {
  border-radius: 23px 0px 0px 23px !important;
  width: 10% !important;
}

/* .react-bootstrap-table {
  overflow: auto;
  font-size: 12px;
} */

.tableList table {
  table-layout: auto !important;
  white-space: nowrap;
}

.burguer {
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background-color: #32058d;
  transform-origin: 1px;
  transition: all 0.5 ease;
}

.hamburguer1 {
  display: none;
  z-index: 6;
}

.collaboratoRH__org {
  cursor: not-allowed;
  pointer-events: all;
  display: flex;
  flex-wrap: wrap;
}

.collaboratoRH__org2 {
  display: flex;
  flex-wrap: wrap;
}

input.form-control.rhadmin {
  width: 100% !important;
}

.custom-input-dropdown-field-wrapper {
  height: 40px;
}

.postal__Code {
  height: 18px;
}

.collaboratoRH__Button {
  display: flex;
  margin-top: 20px;
  margin-bottom: 10px;
  align-items: flex-end;
  flex-direction: row;
  justify-content: space-between;
}

.collaboratoRH__Dropdown {
  pointer-events: none;
  margin-bottom: 5px !important;
}

.collaboratoRH__Dropdown2 {
  margin-bottom: 5px !important;
}

.collaboratorRH__FormControl {
  pointer-events: none;
  margin-bottom: 5px !important;
}

.collaboratorRH__FormControl2 {
  margin-bottom: 5px !important;
}

.collaboratorRH__FormComments {
  pointer-events: none;
  margin-bottom: 5px !important;
  height: 150px !important;
}

.rhcollaborator__labels__postalCode {
  height: 1.1rem;
}

.mb-3>a {
  color: black;
  text-decoration: none;
  /* color: inherit; */
}

.collaboratorRH_checkbox {
  display: flex;
  align-items: flex-end;
  padding-left: 30px;
}

.RHAcademic__checkBox {
  padding: 0 50px 0 50px;
}

/* 
Form.Label {
  color: blue;
} */

.collaboratorRH_checkboxTax {
  display: flex;
  flex-direction: column;
}

.collaboratorRH_checkboxDiv {
  display: flex;
}

/* 
Exemplo 1  */
.collaboratorRH__name {
  margin-left: 12px;
  color: #4484f4;
}

.collaboratoRH__view {
  transform-origin: left top;
  transform: scale(0.9);
}

.responsiveTable {
  white-space: nowrap !important;
  table-layout: auto;
}

/*
.rhcollaborator__labels {
  font-weight: bold;
}
*/

/* Exempl 1 label */
.rhcollaborator__labels {
  font-weight: 600;
  margin: 0px 0px 8px 15px;
  color: #32058dd5;
}

.rhcollaborator__labels__ {
  font-weight: 500;
  margin: 0px 0px 8px 15px;
  color: #32058dd5;
}

.rhcollaborator__labels__before {
  font-weight: 600;
  margin: 0px 0px 8px 15px;
  color: #32058dd5;
}

.rhcollaborator__labels__after {
  font-weight: 600;
  margin: 0px 0px 8px 15px;
  color: #32058dd5;
  display: none;
}

@media (max-width: 810px) {
  .rhcollaborator__labels__before {
    display: none;
  }

  .rhcollaborator__labels__after {
    font-weight: 600;
    margin: 0px 0px 8px 15px;
    color: #32058dd5;
    display: inline-block;
  }

  .hamburguer1 {
    display: block;
    padding-top: 10px;
    margin-left: 12px;
    z-index: 6;
  }

  .custom-tabs {
    display: none;
  }

  .tabsRequests{
    display: flex!important;
  }

  /* .tableList {
    font-size: 14px;
    white-space: nowrap;
  } */
}

@media only screen and (min-width: 888px) and (max-width: 1139px) {
  .rhcollaborator__labels__before {
    display: none;
  }

  .rhcollaborator__labels__after {
    font-weight: 600;
    margin: 0px 0px 8px 15px;
    color: #32058dd5;
    display: inline-block;
  }
}

/* Exemplo 2 */

/* .collaboratoRH__view {
  transform: scale(0.9);
}

.rhcollaborator__labels {
  font-weight: bold;
  color: #4484f4;
}

.collaboratorRH__name {
  font-weight: bold;
  color: #4484f4;
} */
/* .form-group {
  padding: 0px;
} */