@import "theming.scss";

@mixin tabs($theme) {
  .custom-tabs {
    .nav-link {
      border: none !important;
      border-bottom: 2px solid transparent !important;
    }
    .nav-link:hover {
      border: none !important;
      border-color: theme-color($theme, "uniksystemColor") !important;
      border-bottom: 2px solid !important;
    }
    .active {
      color: theme-color($theme, "uniksystemColor") !important;
      background-color: transparent !important;
      border: none !important;
      border-color: theme-color($theme, "uniksystemColor") !important;
      border-bottom: 2px solid !important;
    }
    a {
      color: theme-color($theme, "textColor") !important;
    }
    a:hover {
      color: theme-color($theme, "uniksystemColor") !important;
    }
  }
  .tabsRequests{
    display: flex!important;
  }
}
